body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
@media (max-width: 767px) {
  .App {
    display: block !important;
  }
}
.App {
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
  /* justify-content: space-around; */
  width: 100%;
}
.first video {
  object-fit: contain !important;
}
@media (max-width: 767px) {
  .App .first {
    padding-left: 0px !important;
    position: absolute !important;
    width: 200px !important;
    height: 200px !important;
    bottom: 100px;
    left: 20px;
    z-index: 99;
  }
  .App .first .controls {
    display: flex !important;
    background: transparent !important;
    bottom:-115px;
    margin-left: 30px!important;
  }
  .App .first .controls p img {
    width: 60px;
    height: 60px;
    margin: 0 10px;
  }
  .App .first .vid  {
    width: 100% !important;
    height: 100% !important;
  }
}
@media (max-width: 367px) {
  .App .first .controls {
    display: flex !important;
    background: transparent !important;
    bottom: -125px;
    margin-left: 10px!important;
  }
}
.second video {
  object-fit: contain !important;
} 
@media (max-width: 767px) {
  .App .second  {
    padding-left: 0px !important;
  }
  .App  .second .controls p img {
    width: 45px;
    height: 45px;
    margin: 0 10px;
  }
  .App .second .controls {
    display: flex !important;
    background: transparent !important;
    top:0 !important;
    bottom: unset !important;
  }
  .App .second .vid  {
    width: 100% !important;
    height: 100% !important;
  }
}
#videos{
  position: relative;
  height: 100vh;
  width: 100vw;
  margin: auto;
  border-radius: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
  justify-items: center;
  align-items: center;
  min-height: 80vh;
}


.vid{
  position: relative;
  background-color:black;
  height: 95%;
  width: 95%;
  /* border-radius: 30px; */
  box-shadow: var(--shadow);

}

.controls{
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  margin: 20px auto;
  /* padding: 10px 10px; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  align-items: center;
  justify-items: center;
  /* background: rgba(0, 0, 0, 0.6); */
  border-radius: 10px;
  z-index: 1;
  /* width: 400px; */
  max-width: 60vw;
}

.controls p{
 
  cursor: pointer;
 
}

.controls > input{
  padding: 11px 10px;
  width: 60px;
  border-radius: 5px;
}

.controls p.on{
  background: white;
  color: black;
}

.join{
  position: absolute;
  z-index: 1;
  width: 30vw;
  height: fit-content;
  height: -moz-max-content;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  background-color: #099dfd;
  padding: 30px;
  border-radius: 10px;
  width: 500px;
  max-width: 75vw;
}

.join  input{
  padding: 15px;
  font-size: 1.1rem;
  outline: none;
  border: none;
  border-radius: 5px;
  width: 80%;
  display: block;
  margin:  50px auto;
}

.join  button{
  display: block;
  min-width: 200px;
  padding: 10px 0;
  text-align: center;
  border-radius: 10px;
  background-color: white;
  outline: none;
  border: none;
  font-size: 1.1rem;
  font-weight: 400;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}
